@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html {
  height: 100%;
  margin: 0;
  background-color: #f0f0f0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  height: 100%;
  margin: 0;
  background-color: #f0f0f0;
}

#root {
  height: 100%;
  margin: 0;
  background-color: #f0f0f0;
}

.ptr,
.ptr__children {
  height: auto !important;
  width: 100%;
  overflow: visible !important;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.refresh-spinner {
  width: 150px;
  height: 150px;
  animation: spin 1.5s infinite;
}

#notistack-snackbar {
  width: 14.6rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.5, 0.1, 0.25, 1);
  }
  100% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.5, 0.1, 0.25, 1);
  }
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  #root {
    padding-top: max(1.25rem, env(safe-area-inset-top));
  }
}
@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
}